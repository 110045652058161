import React, { useEffect, useRef, useState } from "react"
import "../assets/styles/components/doclandingnotice.scss"

const Landingnotice = (props: { bannerImage: any; NoticeHeadtext: any; NoticeSubtext: any; metaTitle: any }) => {
  const { bannerImage, NoticeHeadtext, NoticeSubtext, metaTitle } = props

  const bannerRef: any = useRef(null)
  const [height, setHeight] = useState(600)

  useEffect(() => {
    let timeout: NodeJS.Timeout

    // handles setting the iframe's height on window resize to maintain aspect ratio
    const updateSize = () => {
      if (!bannerRef?.current) return

      clearTimeout(timeout)

      timeout = setTimeout(() => {
        setHeight(bannerRef?.current?.clientWidth / (16 / 9))
      }, 100)
    }

    updateSize()

    window.addEventListener("resize", updateSize)
    return () => window.removeEventListener("resize", updateSize)
  }, [bannerRef])
  return (
    <div className="landing--banner--wrapper">
      <div className="landing--banner--image--wrapper" style={{ height: height }} ref={bannerRef}>
        <img src={bannerImage} alt="" />
      </div>
      <div className="landing--banner--overlap--wrapper">
        <div className="landing--banner--overlap--text--inner">
          <h2 className="landing--banner--overlap--headtext">{NoticeHeadtext}</h2>
          <p className="landing--banner--overlap--subtext">{NoticeSubtext}</p>
          <p className="meta">{metaTitle}</p>
        </div>
      </div>
    </div>
  )
}

export default Landingnotice
