import React, { useEffect, useState } from "react"
import "../assets/styles/page/docindex.scss"
import { graphql, Link } from "gatsby"
import ArticleCard from "../components/ArticleCard"
import Landingnotice from "../components/landingnotice"
import Layout from "../components/layout"
import SEO from "../components/seo/seo"

const LandingPage = ({ data }) => {
  // Array of all news articles
  const Allarticles = data.allContentfulLandingPage.nodes[0].allContentList
  const MetaInfo = data.allContentfulLandingPage.nodes[0].seo
  const { landingNoticeSubtext, slug, landingBanner, landingNoticeTitle, landingNoticeMeta } =
    data.allContentfulLandingNotice.nodes[0]

  // State for the list
  const [list, setList] = useState<any[]>([])
  const [newList, setNewList] = useState([...list])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(list.length > 1)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  useEffect(() => {
    const allContentfulList = Allarticles.map(
      (article: {
        titleOfArticle: any
        titleOfProject: any
        videoTitle: any
        bannerUrl: { fluid: { src: any } }
        videoThumbnail: { fluid: { src: any } }
        metaTitle: any
        projectDescription: any
        contentCategory: string
        metaDescription: any
        videoCategory: any
        ProjectCategory: any
        contentful_id: any
        slug: any
      }) => ({
        title: `${article?.titleOfArticle || article?.titleOfProject || article.videoTitle}`,
        image: `${article.bannerUrl?.fluid?.src || article.videoThumbnail?.fluid?.src}`,
        subtitle: `${article.metaTitle || article.metaTitle || article.projectDescription}`,
        contentCategory: article.contentCategory,
        category: `${article.metaDescription || article.videoCategory || article.ProjectCategory}`,
        id: `${article.contentful_id}`,
        slug:
          article.contentCategory === "article"
            ? `/doc/article/doc-article/${article.slug}`
            : article.contentCategory === "project"
            ? `/doc/project/doc-projects/${article.slug}`
            : article.contentCategory === "video"
            ? `/doc/article/doc-videos/${article.slug}`
            : ""
      })
    )

    setList(allContentfulList)
    setNewList(allContentfulList.slice(0, 8))
  }, [])

  //Check if there is more
  useEffect(() => {
    const isMore = newList.length < list.length
    setHasMore(isMore)
  }, [newList]) //eslint-disable-line

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = newList.length
      const isMore = currentLength < list.length
      const nextResults = isMore ? list.slice(currentLength, currentLength + 6) : []
      setNewList([...newList, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  return (
    <div className="doc--landing--page--wrapper">
      <SEO title={MetaInfo.title} description={MetaInfo.description} image={MetaInfo.image.fluid.src} />

      <Layout>
        <div className="docs--content--container">
          <div className="landing--page--banner">
            <Link to={`/doc/${slug}`} key={landingNoticeSubtext}>
              <Landingnotice
                bannerImage={landingBanner.fluid.src}
                NoticeHeadtext={landingNoticeTitle}
                NoticeSubtext={landingNoticeSubtext}
                metaTitle={landingNoticeMeta}
              />
            </Link>
          </div>
          <div className="All--article--list">
            {newList.map(
              (allarticles: { title: any; subtitle: any; id: any; category: any; image: any; slug: any }) => {
                const { title, subtitle, category, image, slug, id } = allarticles
                return (
                  <Link to={slug} className="all-artile--card--wrapper" key={slug}>
                    <ArticleCard
                      key={id}
                      articleImage={image}
                      articleHeadtext={title}
                      articleSubtext={subtitle}
                      meta={category}
                    />
                  </Link>
                )
              }
            )}
          </div>

          <div className="load--more--button">{hasMore ? <button onClick={handleLoadMore}>Load More</button> : ""}</div>
        </div>
      </Layout>
    </div>
  )
}

export default LandingPage

export const query = graphql`
  query landingcontentAndlandingnotice {
    allContentfulLandingPage {
      nodes {
        allContentList {
          ... on ContentfulArticle {
            id
            metaDescription
            metaTitle
            titleOfArticle
            bannerUrl {
              fluid {
                src
              }
            }
            slug
            contentful_id
            contentCategory
          }
          ... on ContentfulProject {
            id
            titleOfProject
            slug
            contentful_id
            ProjectCategory
            projectDescription
            bannerUrl {
              fluid {
                src
              }
            }
            contentCategory
          }
          ... on ContentfulVideo {
            id
            videoTitle
            videoCategory
            contentful_id
            metaTitle
            slug
            videoThumbnail {
              fluid {
                src
              }
            }
            contentCategory
          }
        }
        seo {
          title
          description
          image {
            fluid {
              src
            }
          }
        }
      }
    }
    allContentfulLandingNotice {
      nodes {
        landingNoticeTitle
        landingNoticeSubtext
        landingNoticeMeta
        slug
        contentful_id
        landingBanner {
          fluid {
            src
          }
        }
      }
    }
  }
`
